export const Clearance = {
  system: 0,
  regular: 1,
  clerk: 2,
  manager: 3,
  admin: 4,
};

export const Clearances = [
  {
    title: "SYS",
    value: 0,
  },
  {
    title: "REGULAR",
    value: 1,
  },
  {
    title: "ENTRY CLERK",
    value: 2,
  },
  {
    title: "LABORATORY MANAGER",
    value: 3,
  },
  {
    title: "INSTITUTION ADMIN",
    value: 4,
  },
];

export function userType(clearance) {
  return Clearances.find((c) => c.value == clearance).title;
}
