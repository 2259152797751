<template>
  <div class="search-wrapper">
    <div class="text-center mb-10 px-2">
      <h1 class="display-1 font-weight-bold">
        Find equipment and laboratories
      </h1>
      <p>for research and innovation in Nigeria</p>
    </div>

    <v-card color="transparent" flat class="mx-auto pa-5" max-width="800px">
      <v-form ref="form" @submit.prevent="handleSearch">
        <v-text-field
          label="Search here"
          autocomplete="search"
          v-model="term"
          required
          clearable
          outlined
          rounded
          single-line
          hide-details
          prepend-inner-icon="mdi-magnify"
          placeholder="What equipment can we find for you?"
        ></v-text-field>
        <p class="text-center">
          <v-btn
            large
            rounded
            type="submit"
            color="pink darken-1"
            class="white--text mt-2"
          >
            Search
          </v-btn>
        </p>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    term: null,
  }),
  methods: {
    handleSearch() {
      if (this.term) {
        this.$router.push({
          name: "results",
          query: { term: this.term },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.search-wrapper {
  margin-top: 22vh;
}
</style>