import Vue from "vue";
import VueRouter from "vue-router";
import SearchView from "../views/SearchView.vue";
import AdministrationComponent from "@/components/admin/DefaultComponent";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/search",
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
  },
  {
    path: "/results",
    name: "results",
    component: () =>
      import(/* webpackChunkName: "results" */ "../views/ResultsView.vue"),
  },
  {
    path: "/equipment/:uid",
    name: "equipment",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "../views/equipment/EquipmentDetails.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/admin",
    component: AdministrationComponent,
    redirect: "/admin/equipment",
    children: [
      {
        path: "equipment",
        name: "adminEquipmentList",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/equipment/EquipmentList.vue"
          ),
      },
      {
        path: "equipment/new",
        name: "adminNewEquipment",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/equipment/NewEquipmentForm.vue"
          ),
      },
      {
        path: "equipment/details/:id",
        name: "adminEquipmentUpdate",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/equipment/UpdateEquipmentForm.vue"
          ),
      },
      {
        path: "institutions",
        name: "adminInstitutionList",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/institution/InstitutionList.vue"
          ),
      },
      {
        path: "institutions/new",
        name: "adminNewInstitution",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/institutions/NewInstitutionForm.vue"
          ),
      },
      {
        path: "institutions/details/:id",
        name: "adminInstitutionUpdate",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/institutions/UpdateInstitutionForm.vue"
          ),
      },
      {
        path: "users",
        name: "usersList",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/users/UsersList.vue"
          ),
      },
      {
        path: "users/register",
        name: "registerUser",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/users/RegisterUser.vue"
          ),
      },
      {
        path: "users/details/:id",
        name: "updateUser",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/users/UpdateUser.vue"
          ),
      },
      {
        path: "institution",
        name: "institution",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/institutions/InstitutionUpdate.vue"
          ),
      },
      {
        path: "laboratories",
        name: "institutionLabs",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../components/laboratories/InstitutionLabs.vue"
          ),
      },
    ],
  },
  // will match everything and put it under `$route.params.pathMatch`
  { path: "/:pathMatch(.*)*", name: "NotFound", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
