<template>
  <v-snackbar bottom class="snackbar" v-model="snackbar" :color="snackbarColor">
    {{ snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn icon class="mr-2" v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "red",
    };
  },
  methods: {
    showSnackbar({ type = "default", message }) {
      this.snackbarColor = type;
      this.snackbarText = message;
      this.snackbar = true;
    },
  },
  created() {
    this.$bus.$on("snackbar", this.showSnackbar);
  },
  beforeDestroy() {
    this.$bus.$off("snackbar");
  },
};
</script>

<style lang="scss" scoped>
.snackbar {
  margin-top: 4.2em;
}
.v-snack__wrapper.theme--dark.success {
  background-color: rgb(221, 66, 66);
  color: hsla(0, 0%, 100%, 0.87);
}
</style>