import Vue from "vue";
import axios from "axios";

const UNAUTHORIZED = [401, 403];

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const USER = localStorage.getItem("labEqUser");
    if (USER) {
      const token = localStorage.getItem("labEqToken");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    window.Event.$emit("request.initiated");
    return config;
  },
  (error) => {
    Promise.reject(error);
    window.Event.$emit("request.ended");
  }
);

axios.interceptors.response.use(
  (response) => {
    window.Event.$emit("request.ended");
    return response;
  },
  (error) => {
    window.Event.$emit("request.ended");
    const { status } = error.response;
    if (status) {
      if (UNAUTHORIZED.includes(status)) {
        // localStorage.removeItem("labEqAuth");
        // localStorage.removeItem("labEqUser");

        // window.Event.$emit("logout");

        // Send a notification to let them know what has happened

        window.Event.$emit("action.unauthorized");

        // window.location.href = "/#/login";
        window.Event.$emit("logout");
      }
      return Promise.reject(error);
    }
  }
);

window.axios = Vue.prototype.$axios = axios;
